<template>
  <div class="subPage">
    <div class="subPageTitle">
      <div class="subPageTitleText">
        <p>自提查询</p>
      </div>
    </div>
    <generalBill :tableDatas="tableDatas" userType="seller" @upData="getDatas"  />
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalPage"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import generalBill from "../../../base/generalSelfBill/generalSelfBill";
export default {
  data() {
    return {
      current: 1,
      pageSize: 10,
      totalPage: null,
      tableDatas: []
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  components: {
    generalBill
  },
  mounted() {
    this.getDatas();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDatas();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDatas();
    },
    getDatas() {
      protocolFwd.param_querySelfPickUp.param.page = this.current - 1;
      protocolFwd.param_querySelfPickUp.param.size = this.pageSize;
      protocolFwd.param_querySelfPickUp.param.firmId = this.sessionInfoGetter.firmId;
      protocolFwd.param_querySelfPickUp.param.typeInList = [0,1,2];
      http.postFront(protocolFwd.param_querySelfPickUp).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          for (let i = 0; i < value.content.length; i++) {
            if(!value.content[i].holdCode) {
              value.content[i].holdCode = '---'
            }
            // value.content[i].
          }
          this.tableDatas = value.content;
          this.totalPage = value.totalElements;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    }
  }
};
</script>
<style scoped>
.subPage {
  padding: 0 15px;
}
.subPageTitle {
  overflow: hidden;
}
.subPageTitle .subPageTitleText {
  float: left;
  line-height: 40px;
}
.subPageTitle .subPageTitleText p {
  font-size: 16px;
}
.tableList {
  margin-top: 20px;
}
.deliveryContent {
  line-height: 30px;
}
.subPage .pagination {
  margin-top: 15px;
}
</style>
